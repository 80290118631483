.logo {
  width: 40px;
  height: 40px;
}

.navbar {
  position: relative;
  height: 80px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
}

.title-header {
  font-size: 56px;
  line-height: 1.2;
  padding: 64px 0px 16px;
}

.m-button {
  position: sticky;
  top: 0px;
  box-shadow: none;
  transition: background-color 200ms ease 0s;
  background-color: rgb(255, 255, 255);
  margin-bottom: 48px;
}

.line-button {
  padding: 16px 16px 0px;
  max-width: 1136px;
}

.grid-button {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0px;
  grid-template-rows: auto;
}

.jPdAGF {
  order: 1;
  margin-bottom: 16px;
}

.ldtDko {
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

.btn-text-search {
  background: transparent;
  border: 0px;
  color: rgb(20, 21, 26);
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 300;
  outline: 0px;
  padding: 8px 12px;
  text-align: center;
}

.btn-text-search:hover {
  background: transparent;
  border: 0px;
  color: rgb(20, 21, 26);
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 800;
  outline: 0px;
  padding: 8px 12px;
  text-align: center;
}

.line-content {
  padding: 0px 16px;
  max-width: 1136px;
}

.content {
  border-bottom: 1px solid rgb(230, 232, 234);
  padding-bottom: 56px;
  padding-top: 56px;
}

.content:first-child {
  padding-top: 0px;
}

.krszTj {
  display: grid;
  grid-template-columns: minmax(auto, 200px) 1fr;
  grid-template-rows: auto;
}

.hnJUdi {
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke: 1px rgb(20, 21, 26);
  color: rgb(20, 21, 26);
  font-size: 64px;
  font-weight: 600;
  line-height: 80px;
}

a {
  color: black !important;
  text-decoration: none !important;
}

.a-content {
  border-radius: 16px;
  display: block;
  margin-bottom: 16px;
  padding: 24px;
}

.a-content:hover {
  border-radius: 16px;
  display: block;
  margin-bottom: 16px;
  padding: 24px;
  border-radius: 15px;
  background-color: rgba(230, 232, 234, 0.5);
}

.header-content {
  font-size: 32px;
  line-height: 40px;
  word-break: break-word;
}

.description {
  color: rgb(20, 21, 26);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.45;
}
